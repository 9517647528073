
import { mapGetters } from 'vuex';

export default {
    name: 'BaseFooter',
    data() {
        return {
            menu: [
                { title: 'Блог', route: 'blog' },
                { title: 'F.A.Q', route: 'faq' },
                { title: 'Тарифы', route: '', link: 'https://cofi.ru/api/file/inline/00aa2060-eb2a-11ed-ab2f-2dc1430be6e4', type: '_blank'},
                { title: 'Документы платформы', route: 'documents' },
                { title: 'Контакты', route: '', link: '/contacts' }
            ]
        }
    },
    computed: {
        isInvestor() {
            return this.role && this.role === 'investor';
        },
        ...mapGetters('profile', ['role'])
    },
    methods: {
        handleClickLogo() {
            if (this.$route.path === '/') {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            } else {
                this.$router.push('/');
            }
        },
        handleClickItem(item, type = '_self') {
            if (item.link) window.open(item.link, type);
        }
    }
}
