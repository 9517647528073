import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=590e9b34&scoped=true&lang=pug&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=590e9b34&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590e9b34",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseMobileMenu: require('/app/components/base/mobile/Menu.vue').default,LayoutContainerBigAdaptive: require('/app/components/layout/ContainerBigAdaptive.vue').default})
