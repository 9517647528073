export const RefreshTokenProvider = () => import('../../components/RefreshTokenProvider.vue' /* webpackChunkName: "components/refresh-token-provider" */).then(c => wrapFunctional(c.default || c))
export const ApplicationBox = () => import('../../components/application/Box.vue' /* webpackChunkName: "components/application-box" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCollateral = () => import('../../components/application/Collateral.vue' /* webpackChunkName: "components/application-collateral" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCreationBankAccount = () => import('../../components/application/CreationBankAccount.vue' /* webpackChunkName: "components/application-creation-bank-account" */).then(c => wrapFunctional(c.default || c))
export const ApplicationHead = () => import('../../components/application/Head.vue' /* webpackChunkName: "components/application-head" */).then(c => wrapFunctional(c.default || c))
export const ApplicationPledgeItem = () => import('../../components/application/PledgeItem.vue' /* webpackChunkName: "components/application-pledge-item" */).then(c => wrapFunctional(c.default || c))
export const ApplicationPledgeSelect = () => import('../../components/application/PledgeSelect.vue' /* webpackChunkName: "components/application-pledge-select" */).then(c => wrapFunctional(c.default || c))
export const ApplicationPledgeSelectItem = () => import('../../components/application/PledgeSelectItem.vue' /* webpackChunkName: "components/application-pledge-select-item" */).then(c => wrapFunctional(c.default || c))
export const ApplicationPledgeSelectSection = () => import('../../components/application/PledgeSelectSection.vue' /* webpackChunkName: "components/application-pledge-select-section" */).then(c => wrapFunctional(c.default || c))
export const BaseFooter = () => import('../../components/base/Footer.vue' /* webpackChunkName: "components/base-footer" */).then(c => wrapFunctional(c.default || c))
export const BaseHeader = () => import('../../components/base/Header.vue' /* webpackChunkName: "components/base-header" */).then(c => wrapFunctional(c.default || c))
export const BaseModals = () => import('../../components/base/Modals.vue' /* webpackChunkName: "components/base-modals" */).then(c => wrapFunctional(c.default || c))
export const BaseProfileMenu = () => import('../../components/base/ProfileMenu.vue' /* webpackChunkName: "components/base-profile-menu" */).then(c => wrapFunctional(c.default || c))
export const BlogItemPreview = () => import('../../components/blog/BlogItemPreview.vue' /* webpackChunkName: "components/blog-item-preview" */).then(c => wrapFunctional(c.default || c))
export const BalanceReplenishBox = () => import('../../components/balance/ReplenishBox.vue' /* webpackChunkName: "components/balance-replenish-box" */).then(c => wrapFunctional(c.default || c))
export const BalanceSuccessTransfer = () => import('../../components/balance/SuccessTransfer.vue' /* webpackChunkName: "components/balance-success-transfer" */).then(c => wrapFunctional(c.default || c))
export const BalanceTh = () => import('../../components/balance/Th.vue' /* webpackChunkName: "components/balance-th" */).then(c => wrapFunctional(c.default || c))
export const DashboardBannerApproved = () => import('../../components/dashboard/DashboardBannerApproved.vue' /* webpackChunkName: "components/dashboard-banner-approved" */).then(c => wrapFunctional(c.default || c))
export const DashboardBannerDeclaration = () => import('../../components/dashboard/DashboardBannerDeclaration.vue' /* webpackChunkName: "components/dashboard-banner-declaration" */).then(c => wrapFunctional(c.default || c))
export const DashboardBannerModeration = () => import('../../components/dashboard/DashboardBannerModeration.vue' /* webpackChunkName: "components/dashboard-banner-moderation" */).then(c => wrapFunctional(c.default || c))
export const DashboardCreateProject = () => import('../../components/dashboard/DashboardCreateProject.vue' /* webpackChunkName: "components/dashboard-create-project" */).then(c => wrapFunctional(c.default || c))
export const DashboardCreateType = () => import('../../components/dashboard/DashboardCreateType.vue' /* webpackChunkName: "components/dashboard-create-type" */).then(c => wrapFunctional(c.default || c))
export const DashboardPhoneModal = () => import('../../components/dashboard/DashboardPhoneModal.vue' /* webpackChunkName: "components/dashboard-phone-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonAppDataWaiting = () => import('../../components/common/AppDataWaiting.vue' /* webpackChunkName: "components/common-app-data-waiting" */).then(c => wrapFunctional(c.default || c))
export const CommonDeclaration = () => import('../../components/common/Declaration.vue' /* webpackChunkName: "components/common-declaration" */).then(c => wrapFunctional(c.default || c))
export const CommonDialogModalContent = () => import('../../components/common/DialogModalContent.vue' /* webpackChunkName: "components/common-dialog-modal-content" */).then(c => wrapFunctional(c.default || c))
export const CommonFormattedImage = () => import('../../components/common/FormattedImage.vue' /* webpackChunkName: "components/common-formatted-image" */).then(c => wrapFunctional(c.default || c))
export const CommonHeaderBalance = () => import('../../components/common/HeaderBalance.vue' /* webpackChunkName: "components/common-header-balance" */).then(c => wrapFunctional(c.default || c))
export const CommonResendSms = () => import('../../components/common/ResendSms.vue' /* webpackChunkName: "components/common-resend-sms" */).then(c => wrapFunctional(c.default || c))
export const CommonResendSmsInvestCancel = () => import('../../components/common/ResendSmsInvestCancel.vue' /* webpackChunkName: "components/common-resend-sms-invest-cancel" */).then(c => wrapFunctional(c.default || c))
export const CommonShareSocialNetwork = () => import('../../components/common/ShareSocialNetwork.vue' /* webpackChunkName: "components/common-share-social-network" */).then(c => wrapFunctional(c.default || c))
export const DocumentsCategory = () => import('../../components/documents/DocumentsCategory.vue' /* webpackChunkName: "components/documents-category" */).then(c => wrapFunctional(c.default || c))
export const DocumentsItem = () => import('../../components/documents/DocumentsItem.vue' /* webpackChunkName: "components/documents-item" */).then(c => wrapFunctional(c.default || c))
export const DocumentsVersions = () => import('../../components/documents/DocumentsVersions.vue' /* webpackChunkName: "components/documents-versions" */).then(c => wrapFunctional(c.default || c))
export const ElementLoader = () => import('../../components/element/Loader.vue' /* webpackChunkName: "components/element-loader" */).then(c => wrapFunctional(c.default || c))
export const FaqAccordion = () => import('../../components/faq/FaqAccordion.vue' /* webpackChunkName: "components/faq-accordion" */).then(c => wrapFunctional(c.default || c))
export const FaqBanner = () => import('../../components/faq/FaqBanner.vue' /* webpackChunkName: "components/faq-banner" */).then(c => wrapFunctional(c.default || c))
export const FaqNavigation = () => import('../../components/faq/FaqNavigation.vue' /* webpackChunkName: "components/faq-navigation" */).then(c => wrapFunctional(c.default || c))
export const FaqSection = () => import('../../components/faq/FaqSection.vue' /* webpackChunkName: "components/faq-section" */).then(c => wrapFunctional(c.default || c))
export const InvestorCard = () => import('../../components/investor/InvestorCard.vue' /* webpackChunkName: "components/investor-card" */).then(c => wrapFunctional(c.default || c))
export const LayoutContainer = () => import('../../components/layout/Container.vue' /* webpackChunkName: "components/layout-container" */).then(c => wrapFunctional(c.default || c))
export const LayoutContainerBigAdaptive = () => import('../../components/layout/ContainerBigAdaptive.vue' /* webpackChunkName: "components/layout-container-big-adaptive" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalDefault = () => import('../../components/modals/ModalDefault.vue' /* webpackChunkName: "components/modals-modal-default" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalProjectInvest = () => import('../../components/modals/ModalProjectInvest.vue' /* webpackChunkName: "components/modals-modal-project-invest" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalProjectInvestCancel = () => import('../../components/modals/ModalProjectInvestCancel.vue' /* webpackChunkName: "components/modals-modal-project-invest-cancel" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalProjectInvestCancelConfirm = () => import('../../components/modals/ModalProjectInvestCancelConfirm.vue' /* webpackChunkName: "components/modals-modal-project-invest-cancel-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalProjectInvestConfirm = () => import('../../components/modals/ModalProjectInvestConfirm.vue' /* webpackChunkName: "components/modals-modal-project-invest-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalSmsConfirm = () => import('../../components/modals/ModalSmsConfirm.vue' /* webpackChunkName: "components/modals-modal-sms-confirm" */).then(c => wrapFunctional(c.default || c))
export const ModalsModalSpecialFund = () => import('../../components/modals/ModalSpecialFund.vue' /* webpackChunkName: "components/modals-modal-special-fund" */).then(c => wrapFunctional(c.default || c))
export const OisDocuments = () => import('../../components/ois/Documents.vue' /* webpackChunkName: "components/ois-documents" */).then(c => wrapFunctional(c.default || c))
export const OisMusic = () => import('../../components/ois/Music.vue' /* webpackChunkName: "components/ois-music" */).then(c => wrapFunctional(c.default || c))
export const OisObject = () => import('../../components/ois/Object.vue' /* webpackChunkName: "components/ois-object" */).then(c => wrapFunctional(c.default || c))
export const OisParticipants = () => import('../../components/ois/Participants.vue' /* webpackChunkName: "components/ois-participants" */).then(c => wrapFunctional(c.default || c))
export const OisSelectOises = () => import('../../components/ois/SelectOises.vue' /* webpackChunkName: "components/ois-select-oises" */).then(c => wrapFunctional(c.default || c))
export const OisVideo = () => import('../../components/ois/Video.vue' /* webpackChunkName: "components/ois-video" */).then(c => wrapFunctional(c.default || c))
export const OrderDetailDocumentsCategory = () => import('../../components/orderDetail/OrderDetailDocumentsCategory.vue' /* webpackChunkName: "components/order-detail-documents-category" */).then(c => wrapFunctional(c.default || c))
export const OrderDetailDocumentsItem = () => import('../../components/orderDetail/OrderDetailDocumentsItem.vue' /* webpackChunkName: "components/order-detail-documents-item" */).then(c => wrapFunctional(c.default || c))
export const OrderDetailHead = () => import('../../components/orderDetail/OrderDetailHead.vue' /* webpackChunkName: "components/order-detail-head" */).then(c => wrapFunctional(c.default || c))
export const OrderDetailInvestorDoc = () => import('../../components/orderDetail/OrderDetailInvestorDoc.vue' /* webpackChunkName: "components/order-detail-investor-doc" */).then(c => wrapFunctional(c.default || c))
export const OrderDetailNavigation = () => import('../../components/orderDetail/OrderDetailNavigation.vue' /* webpackChunkName: "components/order-detail-navigation" */).then(c => wrapFunctional(c.default || c))
export const OrderDetailPrepayment = () => import('../../components/orderDetail/OrderDetailPrepayment.vue' /* webpackChunkName: "components/order-detail-prepayment" */).then(c => wrapFunctional(c.default || c))
export const ProfileCard = () => import('../../components/profile/Card.vue' /* webpackChunkName: "components/profile-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileAddModule = () => import('../../components/profile/ProfileAddModule.vue' /* webpackChunkName: "components/profile-add-module" */).then(c => wrapFunctional(c.default || c))
export const ProjectCard = () => import('../../components/project/ProjectCard.vue' /* webpackChunkName: "components/project-card" */).then(c => wrapFunctional(c.default || c))
export const ProjectControl = () => import('../../components/project/ProjectControl.vue' /* webpackChunkName: "components/project-control" */).then(c => wrapFunctional(c.default || c))
export const ProjectControlFinanceRound = () => import('../../components/project/ProjectControlFinanceRound.vue' /* webpackChunkName: "components/project-control-finance-round" */).then(c => wrapFunctional(c.default || c))
export const ProjectControlInactive = () => import('../../components/project/ProjectControlInactive.vue' /* webpackChunkName: "components/project-control-inactive" */).then(c => wrapFunctional(c.default || c))
export const ProjectControlOpenRound = () => import('../../components/project/ProjectControlOpenRound.vue' /* webpackChunkName: "components/project-control-open-round" */).then(c => wrapFunctional(c.default || c))
export const ProjectOisItem = () => import('../../components/project/ProjectOisItem.vue' /* webpackChunkName: "components/project-ois-item" */).then(c => wrapFunctional(c.default || c))
export const ProjectOisItems = () => import('../../components/project/ProjectOisItems.vue' /* webpackChunkName: "components/project-ois-items" */).then(c => wrapFunctional(c.default || c))
export const ProjectProgressBar = () => import('../../components/project/ProjectProgressBar.vue' /* webpackChunkName: "components/project-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ProjectShortModal = () => import('../../components/project/ProjectShortModal.vue' /* webpackChunkName: "components/project-short-modal" */).then(c => wrapFunctional(c.default || c))
export const ProjectTimer = () => import('../../components/project/ProjectTimer.vue' /* webpackChunkName: "components/project-timer" */).then(c => wrapFunctional(c.default || c))
export const ProjectView = () => import('../../components/project/ProjectView.vue' /* webpackChunkName: "components/project-view" */).then(c => wrapFunctional(c.default || c))
export const ProjectVitrinaModerationAlert = () => import('../../components/project/ProjectVitrinaModerationAlert.vue' /* webpackChunkName: "components/project-vitrina-moderation-alert" */).then(c => wrapFunctional(c.default || c))
export const SkeletonBox = () => import('../../components/skeleton/SkeletonBox.vue' /* webpackChunkName: "components/skeleton-box" */).then(c => wrapFunctional(c.default || c))
export const SkeletonContent = () => import('../../components/skeleton/SkeletonContent.vue' /* webpackChunkName: "components/skeleton-content" */).then(c => wrapFunctional(c.default || c))
export const SkeletonProjectCard = () => import('../../components/skeleton/SkeletonProjectCard.vue' /* webpackChunkName: "components/skeleton-project-card" */).then(c => wrapFunctional(c.default || c))
export const SkeletonProjectFull = () => import('../../components/skeleton/SkeletonProjectFull.vue' /* webpackChunkName: "components/skeleton-project-full" */).then(c => wrapFunctional(c.default || c))
export const SupportTicket = () => import('../../components/support/SupportTicket.vue' /* webpackChunkName: "components/support-ticket" */).then(c => wrapFunctional(c.default || c))
export const SupportTicketForm = () => import('../../components/support/SupportTicketForm.vue' /* webpackChunkName: "components/support-ticket-form" */).then(c => wrapFunctional(c.default || c))
export const SupportTicketMessage = () => import('../../components/support/SupportTicketMessage.vue' /* webpackChunkName: "components/support-ticket-message" */).then(c => wrapFunctional(c.default || c))
export const UiAccordeonBox = () => import('../../components/ui/AccordeonBox.vue' /* webpackChunkName: "components/ui-accordeon-box" */).then(c => wrapFunctional(c.default || c))
export const UiVBankAccounts = () => import('../../components/ui/VBankAccounts.vue' /* webpackChunkName: "components/ui-v-bank-accounts" */).then(c => wrapFunctional(c.default || c))
export const UiVButton = () => import('../../components/ui/VButton.vue' /* webpackChunkName: "components/ui-v-button" */).then(c => wrapFunctional(c.default || c))
export const UiVCheckbox = () => import('../../components/ui/VCheckbox.vue' /* webpackChunkName: "components/ui-v-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiVDatePicker = () => import('../../components/ui/VDatePicker.vue' /* webpackChunkName: "components/ui-v-date-picker" */).then(c => wrapFunctional(c.default || c))
export const UiVFormGroup = () => import('../../components/ui/VFormGroup.vue' /* webpackChunkName: "components/ui-v-form-group" */).then(c => wrapFunctional(c.default || c))
export const UiVInput = () => import('../../components/ui/VInput.vue' /* webpackChunkName: "components/ui-v-input" */).then(c => wrapFunctional(c.default || c))
export const UiVInputWithSuggestions = () => import('../../components/ui/VInputWithSuggestions.vue' /* webpackChunkName: "components/ui-v-input-with-suggestions" */).then(c => wrapFunctional(c.default || c))
export const UiVLabel = () => import('../../components/ui/VLabel.vue' /* webpackChunkName: "components/ui-v-label" */).then(c => wrapFunctional(c.default || c))
export const UiVPagination = () => import('../../components/ui/VPagination.vue' /* webpackChunkName: "components/ui-v-pagination" */).then(c => wrapFunctional(c.default || c))
export const UiVRadio = () => import('../../components/ui/VRadio.vue' /* webpackChunkName: "components/ui-v-radio" */).then(c => wrapFunctional(c.default || c))
export const UiVSelect = () => import('../../components/ui/VSelect.vue' /* webpackChunkName: "components/ui-v-select" */).then(c => wrapFunctional(c.default || c))
export const UiVTextarea = () => import('../../components/ui/VTextarea.vue' /* webpackChunkName: "components/ui-v-textarea" */).then(c => wrapFunctional(c.default || c))
export const VitrinaTelegramButton = () => import('../../components/vitrina/TelegramButton.vue' /* webpackChunkName: "components/vitrina-telegram-button" */).then(c => wrapFunctional(c.default || c))
export const VitrinaTelegramMessage = () => import('../../components/vitrina/TelegramMessage.vue' /* webpackChunkName: "components/vitrina-telegram-message" */).then(c => wrapFunctional(c.default || c))
export const VitrinaPagination = () => import('../../components/vitrina/VitrinaPagination.vue' /* webpackChunkName: "components/vitrina-pagination" */).then(c => wrapFunctional(c.default || c))
export const ApplicationBoxesCollaterals = () => import('../../components/application/boxes/Collaterals.vue' /* webpackChunkName: "components/application-boxes-collaterals" */).then(c => wrapFunctional(c.default || c))
export const ApplicationBoxesDescription = () => import('../../components/application/boxes/Description.vue' /* webpackChunkName: "components/application-boxes-description" */).then(c => wrapFunctional(c.default || c))
export const ApplicationBoxesDocuments = () => import('../../components/application/boxes/Documents.vue' /* webpackChunkName: "components/application-boxes-documents" */).then(c => wrapFunctional(c.default || c))
export const ApplicationFormsDefault = () => import('../../components/application/forms/Default.vue' /* webpackChunkName: "components/application-forms-default" */).then(c => wrapFunctional(c.default || c))
export const BaseMobileMenu = () => import('../../components/base/mobile/Menu.vue' /* webpackChunkName: "components/base-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseMobileMenuFooterNav = () => import('../../components/base/mobile/MenuFooterNav.vue' /* webpackChunkName: "components/base-mobile-menu-footer-nav" */).then(c => wrapFunctional(c.default || c))
export const BaseMobileMenuNav = () => import('../../components/base/mobile/MenuNav.vue' /* webpackChunkName: "components/base-mobile-menu-nav" */).then(c => wrapFunctional(c.default || c))
export const ProfileEntityBeneficiaries = () => import('../../components/profile/EntityBeneficiaries/index.vue' /* webpackChunkName: "components/profile-entity-beneficiaries" */).then(c => wrapFunctional(c.default || c))
export const ProfileEntityBeneficiariesItem = () => import('../../components/profile/EntityBeneficiaries/item.vue' /* webpackChunkName: "components/profile-entity-beneficiaries-item" */).then(c => wrapFunctional(c.default || c))
export const ProfileEntityGovernments = () => import('../../components/profile/EntityGovernments/index.vue' /* webpackChunkName: "components/profile-entity-governments" */).then(c => wrapFunctional(c.default || c))
export const ProfileEntityGovernmentsItem = () => import('../../components/profile/EntityGovernments/item.vue' /* webpackChunkName: "components/profile-entity-governments-item" */).then(c => wrapFunctional(c.default || c))
export const ProfileBoxesEntityData = () => import('../../components/profile/boxes/EntityData.vue' /* webpackChunkName: "components/profile-boxes-entity-data" */).then(c => wrapFunctional(c.default || c))
export const ProfileBoxesEntityFinancial = () => import('../../components/profile/boxes/EntityFinancial.vue' /* webpackChunkName: "components/profile-boxes-entity-financial" */).then(c => wrapFunctional(c.default || c))
export const ProfileBoxesEntityStructure = () => import('../../components/profile/boxes/EntityStructure.vue' /* webpackChunkName: "components/profile-boxes-entity-structure" */).then(c => wrapFunctional(c.default || c))
export const ProfileEntityBeneficiary = () => import('../../components/profile/entity/EntityBeneficiary.vue' /* webpackChunkName: "components/profile-entity-beneficiary" */).then(c => wrapFunctional(c.default || c))
export const ProfileEntityFinancialOption = () => import('../../components/profile/entity/EntityFinancialOption.vue' /* webpackChunkName: "components/profile-entity-financial-option" */).then(c => wrapFunctional(c.default || c))
export const ProfileEntityGovernment = () => import('../../components/profile/entity/EntityGovernment.vue' /* webpackChunkName: "components/profile-entity-government" */).then(c => wrapFunctional(c.default || c))
export const ProfileIndividualProfileFinance = () => import('../../components/profile/individual/IndividualProfileFinance.vue' /* webpackChunkName: "components/profile-individual-profile-finance" */).then(c => wrapFunctional(c.default || c))
export const ProfileIndividualProfileFinancialOption = () => import('../../components/profile/individual/IndividualProfileFinancialOption.vue' /* webpackChunkName: "components/profile-individual-profile-financial-option" */).then(c => wrapFunctional(c.default || c))
export const ProjectRequirementModal = () => import('../../components/project/modals/ProjectRequirementModal.vue' /* webpackChunkName: "components/project-requirement-modal" */).then(c => wrapFunctional(c.default || c))
export const ProjectSignContractModal = () => import('../../components/project/modals/ProjectSignContractModal.vue' /* webpackChunkName: "components/project-sign-contract-modal" */).then(c => wrapFunctional(c.default || c))
export const UiVDropdownGrouped = () => import('../../components/ui/VDropdown/Grouped.vue' /* webpackChunkName: "components/ui-v-dropdown-grouped" */).then(c => wrapFunctional(c.default || c))
export const UiVDropdownSimple = () => import('../../components/ui/VDropdown/Simple.vue' /* webpackChunkName: "components/ui-v-dropdown-simple" */).then(c => wrapFunctional(c.default || c))
export const UiVFileInputItem = () => import('../../components/ui/VFileInput/Item.vue' /* webpackChunkName: "components/ui-v-file-input-item" */).then(c => wrapFunctional(c.default || c))
export const UiVFileInput = () => import('../../components/ui/VFileInput/index.vue' /* webpackChunkName: "components/ui-v-file-input" */).then(c => wrapFunctional(c.default || c))
export const ProfileEntityData = () => import('../../components/profile/entity/boxes/ProfileEntityData.vue' /* webpackChunkName: "components/profile-entity-data" */).then(c => wrapFunctional(c.default || c))
export const ProfileEntityFinance = () => import('../../components/profile/entity/boxes/ProfileEntityFinance.vue' /* webpackChunkName: "components/profile-entity-finance" */).then(c => wrapFunctional(c.default || c))
export const ProfileEntityStructure = () => import('../../components/profile/entity/boxes/ProfileEntityStructure.vue' /* webpackChunkName: "components/profile-entity-structure" */).then(c => wrapFunctional(c.default || c))
export const ProfilePersonData = () => import('../../components/profile/entity/boxes/ProfilePersonData.vue' /* webpackChunkName: "components/profile-person-data" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
